.card {
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
#dashboard {
  padding: 2rem 4rem;
  margin-bottom: 0%;
}

.dash-heading{
  margin-bottom: -2.8rem;
  margin-top: 3rem;
}

.ref-link{
  cursor: text;
}

.copy-link{
  cursor: pointer;
}

.outline-div {
  width: 100%;
  color: black;
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin-top: 5rem;
  text-align: center;
  box-shadow: 0px 5px 25px #6f5fe1, inset 0px -1px 0px #864becb6;
}

.card{
  border-radius: 1rem !important;
}
.card-div {
  margin: 0.5rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  box-shadow: 0px 2px 5px #6f5fe1, inset 0px -1px 0px #8147e5b6;
  border-radius: 1rem !important;
}
.dashboard {
  display: flex;
  margin: 3% 0%;
  flex: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.button-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.custom-div {
  margin: 5px;
}

.buttons-withdraw{
  background-color: #8247e5!important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
}



@media only screen and (max-width: 500px) {
  #dashboard {
    padding: 5rem 1.5rem 2rem;
  }
  .dashboard {
    display: flex;
    padding: 0;
    margin: 3%;
    /* flex-direction: column; */
    font-size: 12px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .outline-div {
    width: 100%;
    background-color: #fff;
    margin: 0;
    border-radius: 20px;
    margin-top: 2rem;
    text-align: center;
  }
  .custom-div {
    width: 100%;
    margin: 7px auto;
    font-size: 11px;
    text-align: center;
    padding: 8px 0px;
  }
}
@media only screen and (max-width: 300px) {
  .custom-div {
    width: 100%;
    margin: 7px auto;
    font-size: 8.5px;
    text-align: center;
    padding: 8px 0px;
  }
}
