#home {
  height: 100%;
  padding: 10% 0% 5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.b-chain {
  color: #8247e5;
  font-weight: 900;
}
.btn-1 {
  padding-top: 3%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  width: 50%;
}

.buttons-invest{
  background-color: #8247e5  !important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
  margin: 0.5rem !important;
}

.cont-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.des-home {
  padding: 10px;
}
.image-logo:hover {
  transform: rotate(360deg);
  transition: all 1s ease;
}

.buttons-trans {
  margin: 6px 8px;
  background: linear-gradient(270deg, #8247e5 0%, #6f5fe1 96.85%);
  box-shadow: 0px 5px 20px #6f5fe1, inset 0px -1px 0px rgb(0 0 0 / 32%);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
@media only screen and (max-width: 700px) {
  #home {
    flex-direction: column;
    margin: 0;
    padding-top: 4rem;
    font-size: 1rem;
  }
  .i-logo {
    margin-top: 2rem;
    width: 40%;
    height: 40%;
  }
  .btn-1 {
    font-size: 0.8rem;
    margin: 5px 7px;
  }
}
