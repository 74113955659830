@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.me-auto {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.head-brand {
  font-family: 'Pacifico', cursive;
  color: #8247e5;
}
.fab:hover {
  color: #8247e5;
}

.btn-nav-tele{
  background-color:  #8247e5!important;
  border-radius: 50rem !important;

}

.btn-nav-tele:hover{
  background-color: #773cdf !important;
}

.faq{
  color: black;
}

.social-faq{
  color: #8247e5 !important;
  text-decoration: none;
}

.social-faq:hover{
  color: #773cdf !important
}