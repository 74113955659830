#footer {
  background-color: #202020;
  padding: 2% 3% 1%;
  margin-top: 6%;
  font-family: 'Montserrat';
}

.footer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0.5rem 0.5rem 0rem;
  align-items: center;
  margin: 10px 0px;
}
.logodiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}
.btn-foot-div {
  font-size: 11px;
  max-width: 100%;
}

.btn-footer{
  background-color: #8247e5 !important;
  border-radius: 20rem !important;
  margin: 10px !important;
}

.btn-footer:hover{
  background-color: #773cdf !important;
}

.btn-foot .contract{
  text-decoration: none !important;
}

.btn-footer-tele{
  background-color:  #8247e5!important;
  border-radius: 50rem !important;

}

.btn-footer-tele:hover{
  background-color: #773cdf !important;
}

.nav-link1{
  color: #8247e5;
}

.nav-link1:hover{
  color: #773cdf !important;
}


a {
  color: #bdbfc0;
}
@media only screen and (max-width: 700px) {
  .footer {
    flex-direction: column;
  }
  .logodiv {
    flex-direction: column;
  }
}
