.card-div {
  margin: 0.5rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  box-shadow: 0px 5px 25px #6f5fe1, inset 0px -1px 0px #8147e5b6;
  /* border-color: linear-gradient(270deg, #20c1b2 0%, #078093 96.85%); */
}
.card-title {
  font-size: 0.9rem;
  font-weight: 700;
}
