@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&family=Pacifico&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.me-auto {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.head-brand {
  font-family: 'Pacifico', cursive;
  color: #8247e5;
}
.fab:hover {
  color: #8247e5;
}

.btn-nav-tele{
  background-color:  #8247e5!important;
  border-radius: 50rem !important;

}

.btn-nav-tele:hover{
  background-color: #773cdf !important;
}

.faq{
  color: black;
}

.social-faq{
  color: #8247e5 !important;
  text-decoration: none;
}

.social-faq:hover{
  color: #773cdf !important
}
#home {
  height: 100%;
  padding: 10% 0% 5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.b-chain {
  color: #8247e5;
  font-weight: 900;
}
.btn-1 {
  padding-top: 3%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  width: 50%;
}

.buttons-invest{
  background-color: #8247e5  !important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
  margin: 0.5rem !important;
}

.cont-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.des-home {
  padding: 10px;
}
.image-logo:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  transition: all 1s ease;
}

.buttons-trans {
  margin: 6px 8px;
  background: linear-gradient(270deg, #8247e5 0%, #6f5fe1 96.85%);
  box-shadow: 0px 5px 20px #6f5fe1, inset 0px -1px 0px rgb(0 0 0 / 32%);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
@media only screen and (max-width: 700px) {
  #home {
    flex-direction: column;
    margin: 0;
    padding-top: 4rem;
    font-size: 1rem;
  }
  .i-logo {
    margin-top: 2rem;
    width: 40%;
    height: 40%;
  }
  .btn-1 {
    font-size: 0.8rem;
    margin: 5px 7px;
  }
}

.card-div {
  margin: 0.5rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  box-shadow: 0px 5px 25px #6f5fe1, inset 0px -1px 0px #8147e5b6;
  /* border-color: linear-gradient(270deg, #20c1b2 0%, #078093 96.85%); */
}
.card-title {
  font-size: 0.9rem;
  font-weight: 700;
}

.card {
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
#dashboard {
  padding: 2rem 4rem;
  margin-bottom: 0%;
}

.dash-heading{
  margin-bottom: -2.8rem;
  margin-top: 3rem;
}

.ref-link{
  cursor: text;
}

.copy-link{
  cursor: pointer;
}

.outline-div {
  width: 100%;
  color: black;
  background-color: #fff;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin-top: 5rem;
  text-align: center;
  box-shadow: 0px 5px 25px #6f5fe1, inset 0px -1px 0px #864becb6;
}

.card{
  border-radius: 1rem !important;
}
.card-div {
  margin: 0.5rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  box-shadow: 0px 2px 5px #6f5fe1, inset 0px -1px 0px #8147e5b6;
  border-radius: 1rem !important;
}
.dashboard {
  display: flex;
  margin: 3% 0%;
  flex: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.button-div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.custom-div {
  margin: 5px;
}

.buttons-withdraw{
  background-color: #8247e5!important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
}



@media only screen and (max-width: 500px) {
  #dashboard {
    padding: 5rem 1.5rem 2rem;
  }
  .dashboard {
    display: flex;
    padding: 0;
    margin: 3%;
    /* flex-direction: column; */
    font-size: 12px;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .outline-div {
    width: 100%;
    background-color: #fff;
    margin: 0;
    border-radius: 20px;
    margin-top: 2rem;
    text-align: center;
  }
  .custom-div {
    width: 100%;
    margin: 7px auto;
    font-size: 11px;
    text-align: center;
    padding: 8px 0px;
  }
}
@media only screen and (max-width: 300px) {
  .custom-div {
    width: 100%;
    margin: 7px auto;
    font-size: 8.5px;
    text-align: center;
    padding: 8px 0px;
  }
}

#calc {
  padding: 4rem;
  margin-bottom: 4%;
}
.heading {
  padding: 1.5rem 0rem;
}

.cal-heading{
  margin-top: 2rem;
  margin-bottom: -2rem;
}
.outer-div {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  justify-content: space-evenly;
}

.buttons-invest{
  background-color: #8247e5  !important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
  margin: 0.5rem !important;
}

.max-button{
  background-color: #8247e5  !important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
  margin: 0.5rem !important;
}



.slider{
  background-color: #6655e7 !important;
}
.slider-div {
  display: flex;
  margin: 1rem;
  justify-content: space-evenly;
  align-items: center;
}
.list-div {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
@media only screen and (max-width: 700px) {
  #calc {
    padding: 5rem 1.5rem 2rem;
    font-size: 1rem;
  }
  .heading {
    padding: 1.5rem 0rem;
  }
  .outer-div {
    padding: 1rem;
  }
  .slider-div {
    flex-direction: column;
    padding: 1rem;
  }
  .list-div {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 500px) {
  #calc {
    padding: 5rem 1.5rem 2rem;
    font-size: 0.5rem;
  }
  .outer-div {
    padding: 1rem;
  }
  .slider-div {
    flex-direction: column;
    padding: 1rem;
  }
  .list-div {
    flex-direction: column;
    align-items: flex-start;
  }
}

#footer {
  background-color: #202020;
  padding: 2% 3% 1%;
  margin-top: 6%;
  font-family: 'Montserrat';
}

.footer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0.5rem 0.5rem 0rem;
  align-items: center;
  margin: 10px 0px;
}
.logodiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
}
.btn-foot-div {
  font-size: 11px;
  max-width: 100%;
}

.btn-footer{
  background-color: #8247e5 !important;
  border-radius: 20rem !important;
  margin: 10px !important;
}

.btn-footer:hover{
  background-color: #773cdf !important;
}

.btn-foot .contract{
  text-decoration: none !important;
}

.btn-footer-tele{
  background-color:  #8247e5!important;
  border-radius: 50rem !important;

}

.btn-footer-tele:hover{
  background-color: #773cdf !important;
}

.nav-link1{
  color: #8247e5;
}

.nav-link1:hover{
  color: #773cdf !important;
}


a {
  color: #bdbfc0;
}
@media only screen and (max-width: 700px) {
  .footer {
    flex-direction: column;
  }
  .logodiv {
    flex-direction: column;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: #000000;
  color: #fff;
  transition: 0.5s ease;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

