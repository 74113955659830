#calc {
  padding: 4rem;
  margin-bottom: 4%;
}
.heading {
  padding: 1.5rem 0rem;
}

.cal-heading{
  margin-top: 2rem;
  margin-bottom: -2rem;
}
.outer-div {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  justify-content: space-evenly;
}

.buttons-invest{
  background-color: #8247e5  !important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
  margin: 0.5rem !important;
}

.max-button{
  background-color: #8247e5  !important;
  border:  2px outset #8247e5 !important;
  border-radius: 50rem !important;
  margin: 0.5rem !important;
}



.slider{
  background-color: #6655e7 !important;
}
.slider-div {
  display: flex;
  margin: 1rem;
  justify-content: space-evenly;
  align-items: center;
}
.list-div {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
@media only screen and (max-width: 700px) {
  #calc {
    padding: 5rem 1.5rem 2rem;
    font-size: 1rem;
  }
  .heading {
    padding: 1.5rem 0rem;
  }
  .outer-div {
    padding: 1rem;
  }
  .slider-div {
    flex-direction: column;
    padding: 1rem;
  }
  .list-div {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 500px) {
  #calc {
    padding: 5rem 1.5rem 2rem;
    font-size: 0.5rem;
  }
  .outer-div {
    padding: 1rem;
  }
  .slider-div {
    flex-direction: column;
    padding: 1rem;
  }
  .list-div {
    flex-direction: column;
    align-items: flex-start;
  }
}
